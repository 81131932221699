import { lazyDefine } from '@github/catalyst';
lazyDefine('animated-image', () => import('../../components/accessibility/animated-image-element.ts'))
lazyDefine('launch-code', () => import('../../components/account_verifications/launch-code-element.ts'))
lazyDefine('actions-caches-filter', () => import('../../components/actions/actions-caches-filter-element.ts'))
lazyDefine('actions-workflow-filter', () => import('../../components/actions/actions-workflow-filter-element.ts'))
lazyDefine('actions-workflow-list', () => import('../../components/actions/actions-workflow-list-element.ts'))
lazyDefine('variable-value', () => import('../../components/actions/variables/variable-value-element.ts'))
lazyDefine('variables-input', () => import('../../components/actions/variables/variables-input-element.ts'))
lazyDefine('variables-pagination', () => import('../../components/actions/variables/variables-pagination-element.ts'))
lazyDefine('cvss-calculator', () => import('../../components/advisories/cvss-calculator-element.ts'))
lazyDefine('cvss-calculator-metric', () => import('../../components/advisories/cvss-calculator-metric-element.ts'))
lazyDefine('metric-selection', () => import('../../components/advisories/metric-selection-element.ts'))
lazyDefine('severity-calculator', () => import('../../components/advisories/severity-calculator-element.ts'))
lazyDefine('severity-score', () => import('../../components/advisories/severity-score-element.ts'))
lazyDefine('severity-selection', () => import('../../components/advisories/severity-selection-element.ts'))
lazyDefine('severity-selection-next', () => import('../../components/advisories/severity-selection-next-element.ts'))
lazyDefine('severity-tracking', () => import('../../components/advisories/severity-tracking-element.ts'))
lazyDefine('webauthn-status', () => import('../../components/behaviors/webauthn-status-element.ts'))
lazyDefine('downgrade-dialog', () => import('../../components/billing/settings/downgrade-dialog-element.ts'))
lazyDefine('manage-subscription', () => import('../../components/billing/settings/upgrade/manage-subscription-element.ts'))
lazyDefine('pending-cycle-changes-component', () => import('../../components/billing/stafftools/pending-cycle-changes-component-element.ts'))
lazyDefine('create-branch', () => import('../../components/branch/create-branch-element.ts'))
lazyDefine('create-repo-from-selector', () => import('../../components/branch/create-repo-from-selector-element.ts'))
lazyDefine('select-all', () => import('../../components/businesses/people/select-all-element.ts'))
lazyDefine('close-reason-selector', () => import('../../components/closables/buttons/close-reason-selector-element.ts'))
lazyDefine('reopen-reason-selector', () => import('../../components/closables/buttons/reopen-reason-selector-element.ts'))
lazyDefine('alert-dismissal-details', () => import('../../components/code_scanning/alert-dismissal-details-element.ts'))
lazyDefine('pretty-cron', () => import('../../components/code_scanning/pretty-cron-element.ts'))
lazyDefine('timeout-content', () => import('../../components/code_scanning/timeout-content-element.ts'))
lazyDefine('message-list', () => import('../../components/code_scanning/tool_status/message-list-element.ts'))
lazyDefine('sku-list', () => import('../../components/codespaces/advanced_options/sku-list-element.ts'))
lazyDefine('create-button', () => import('../../components/codespaces/create-button-element.ts'))
lazyDefine('editor-forwarder', () => import('../../components/codespaces/editor-forwarder-element.ts'))
lazyDefine('command-palette-page', () => import('../../components/command_palette/command-palette-page-element.ts'))
lazyDefine('command-palette-page-stack', () => import('../../components/command_palette/command-palette-page-stack-element.ts'))
lazyDefine('feed-post', () => import('../../components/conduit/feed-post-element.ts'))
lazyDefine('copilot-signup-choose-plan-type', () => import('../../components/copilot/copilot-signup-choose-plan-type-element.ts'))
lazyDefine('copilot-business-signup-seat-management', () => import('../../components/copilot/seat_management/copilot-business-signup-seat-management-element.ts'))
lazyDefine('loading-context', () => import('../../components/dashboard/loading-context-element.ts'))
lazyDefine('portal-fragment', () => import('../../components/dashboard/portal-fragment-element.ts'))
lazyDefine('query-search', () => import('../../components/dashboard/query-search-element.ts'))
lazyDefine('dependabot-alert-dismissal', () => import('../../components/dependabot_alerts/dependabot-alert-dismissal-element.ts'))
lazyDefine('dependabot-alert-load-all', () => import('../../components/dependabot_alerts/dependabot-alert-load-all-element.ts'))
lazyDefine('dependabot-alert-row', () => import('../../components/dependabot_alerts/dependabot-alert-row-element.ts'))
lazyDefine('dependabot-alert-table-header', () => import('../../components/dependabot_alerts/dependabot-alert-table-header-element.ts'))
lazyDefine('dependabot-updates-paused', () => import('../../components/dependabot/dependabot-updates-paused-element.ts'))
lazyDefine('deferred-diff-lines', () => import('../../components/diffs/deferred-diff-lines-element.ts'))
lazyDefine('edit-history', () => import('../../components/discussions/edit-history-element.ts'))
lazyDefine('conduit-profile-feed-visibility', () => import('../../components/feed/conduit-profile-feed-visibility-element.ts'))
lazyDefine('readme-toc', () => import('../../components/files/readme-toc-element.ts'))
lazyDefine('delayed-loading', () => import('../../components/github/delayed-loading-element.ts'))
lazyDefine('remote-pagination', () => import('../../components/github/remote-pagination-element.ts'))
lazyDefine('dialog-hydro', () => import('../../components/hydro/dialog-hydro-element.ts'))
lazyDefine('track-view', () => import('../../components/hydro/track-view-element.ts'))
lazyDefine('development-menu', () => import('../../components/issues/references/development-menu-element.ts'))
lazyDefine('load-versions', () => import('../../components/marketplace/load-versions-element.ts'))
lazyDefine('math-renderer', () => import('../../components/mathjax/math-renderer-element.ts'))
lazyDefine('memex-project-picker', () => import('../../components/memex/memex-project-picker-element.ts'))
lazyDefine('memex-project-picker-panel', () => import('../../components/memex/memex-project-picker-panel-element.ts'))
lazyDefine('memex-project-picker-interstitial', () => import('../../components/memex/project_list/memex-project-picker-interstitial-element.ts'))
lazyDefine('memex-project-picker-unlink', () => import('../../components/memex/project_list/memex-project-picker-unlink-element.ts'))
lazyDefine('project-buttons-list', () => import('../../components/memex/project_list/project-buttons-list-element.ts'))
lazyDefine('navigation-list', () => import('../../components/navigation/navigation-list-element.ts'))
lazyDefine('notification-shelf-watcher', () => import('../../components/notifications/notification-shelf-watcher-element.ts'))
lazyDefine('feature-request', () => import('../../components/organizations/member_requests/feature-request-element.ts'))
lazyDefine('allowed-values-input', () => import('../../components/organizations/settings/codespaces_policy_form/constraint_row/allowed-values-input-element.ts'))
lazyDefine('host-setup', () => import('../../components/organizations/settings/codespaces_policy_form/constraint_row/host-setup-element.ts'))
lazyDefine('max-value', () => import('../../components/organizations/settings/codespaces_policy_form/constraint_row/max-value-element.ts'))
lazyDefine('codespaces-policy-form', () => import('../../components/organizations/settings/codespaces-policy-form-element.ts'))
lazyDefine('repository-selection-input', () => import('../../components/packages/repository-selection-input-element.ts'))
lazyDefine('experimental-action-menu', () => import('../../components/primer/action_menu/experimental-action-menu-element.ts'))
lazyDefine('select-panel', () => import('../../components/primer/experimental/select-panel-element.ts'))
lazyDefine('internal-nav-list-group', () => import('../../components/primer/experimental/side_panel/nav_list/internal-nav-list-group-element.ts'))
lazyDefine('split-page-layout', () => import('../../components/primer/experimental/split-page-layout-element.ts'))
lazyDefine('toggle-switch', () => import('../../components/primer/experimental/toggle-switch-element.ts'))
lazyDefine('lazy-load-section', () => import('../../components/primer/navigation_list/lazy-load-section-element.ts'))
lazyDefine('profile-timezone', () => import('../../components/profiles/profile-timezone-element.ts'))
lazyDefine('copilot-marketing-popover', () => import('../../components/pull_requests/copilot-marketing-popover-element.ts'))
lazyDefine('file-filter', () => import('../../components/pull_requests/file_tree/file-filter-element.ts'))
lazyDefine('file-tree', () => import('../../components/pull_requests/file_tree/file-tree-element.ts'))
lazyDefine('file-tree-toggle', () => import('../../components/pull_requests/file_tree/file-tree-toggle-element.ts'))
lazyDefine('reactions-menu', () => import('../../components/reactions/reactions-menu-element.ts'))
lazyDefine('pin-organization-repo', () => import('../../components/repositories/pin-organization-repo-element.ts'))
lazyDefine('custom-scopes', () => import('../../components/search/custom-scopes-element.ts'))
lazyDefine('feature-preview-auto-enroll', () => import('../../components/search/feature-preview-auto-enroll-element.ts'))
lazyDefine('qbsearch-input', () => import('../../components/search/qbsearch-input-element.ts'))
lazyDefine('alert-dismissal', () => import('../../components/secret_scanning/alert_centric_view/alert-dismissal-element.ts'))
lazyDefine('coverage-settings', () => import('../../components/security_center/coverage-settings-element.ts'))
lazyDefine('multi-repo-enablement-coordinator', () => import('../../components/security_center/coverage/enablement/multi-repo-enablement-coordinator-element.ts'))
lazyDefine('multi-repo-enablement', () => import('../../components/security_center/coverage/enablement/multi-repo-enablement-element.ts'))
lazyDefine('multi-repo-enablement-setting', () => import('../../components/security_center/coverage/enablement/multi-repo-enablement-setting-element.ts'))
lazyDefine('table-item-selection', () => import('../../components/security_center/coverage/enablement/table-item-selection-element.ts'))
lazyDefine('preview-announcement-button', () => import('../../components/settings/messages/preview-announcement-button-element.ts'))
lazyDefine('recovery-codes', () => import('../../components/settings/recovery-codes-element.ts'))
lazyDefine('project-picker', () => import('../../components/sidebar/project-picker-element.ts'))
lazyDefine('deferred-side-panel', () => import('../../components/site/header/deferred-side-panel-element.ts'))
lazyDefine('notification-indicator', () => import('../../components/site/header/notification-indicator-element.ts'))
lazyDefine('user-drawer-side-panel', () => import('../../components/site/header/user-drawer-side-panel-element.ts'))
lazyDefine('slash-command-toolbar-button', () => import('../../components/slash_commands/slash-command-toolbar-button-element.ts'))
lazyDefine('featured-work', () => import('../../components/sponsors/dashboard/featured-work-element.ts'))
lazyDefine('sponsors-account-switcher', () => import('../../components/sponsors/sponsors-account-switcher-element.ts'))
lazyDefine('variant-menu-item', () => import('../../components/stafftools/azure_exp/variant-menu-item-element.ts'))
lazyDefine('metered-billing-settings-component', () => import('../../components/stafftools/billing/businesses/metered-billing-settings-component-element.ts'))
lazyDefine('billing-transaction-component', () => import('../../components/stafftools/billing/history/billing-transaction-component-element.ts'))
lazyDefine('sponsorships-tabs', () => import('../../components/stafftools/billing/sponsorships-tabs-element.ts'))
lazyDefine('bundle-size-stats', () => import('../../components/stafftools/bundle-size-stats/bundle-size-stats-element.ts'))
lazyDefine('datahpc-staffbar', () => import('../../components/stafftools/data_hpc/datahpc-staffbar-element.ts'))
lazyDefine('react-profiling-toggle', () => import('../../components/stafftools/react/react-profiling-toggle-element.ts'))
lazyDefine('react-staffbar', () => import('../../components/stafftools/react/react-staffbar-element.ts'))
lazyDefine('soft-nav-staffbar', () => import('../../components/stafftools/soft_nav/soft-nav-staffbar-element.ts'))
lazyDefine('soft-nav-staffbar-preview', () => import('../../components/stafftools/soft_nav/soft-nav-staffbar-preview-element.ts'))
lazyDefine('stafftools-invoiced-sponsorship-payment-options', () => import('../../components/stafftools/sponsors/invoiced/stafftools-invoiced-sponsorship-payment-options-element.ts'))
lazyDefine('themed-picture', () => import('../../components/themed_pictures/themed-picture-element.ts'))
lazyDefine('tasklist-block-add-tasklist', () => import('../../components/tracking_blocks/tasklist-block-add-tasklist-element.ts'))
lazyDefine('tasklist-block-title', () => import('../../components/tracking_blocks/tasklist-block-title-element.ts'))
lazyDefine('tracking-block', () => import('../../components/tracking_blocks/tracking-block-element.ts'))
lazyDefine('tracking-block-omnibar', () => import('../../components/tracking_blocks/tracking-block-omnibar-element.ts'))
lazyDefine('two-factor-fallback-sms-config-toggle', () => import('../../components/users/settings/two-factor-fallback-sms-config-toggle-element.ts'))
lazyDefine('two-factor-inline-expander', () => import('../../components/users/settings/two-factor-inline-expander-element.ts'))
lazyDefine('actions-announceable-search-result-summary', () => import('@github-ui/actions-announceable-search-result-summary-element'))
lazyDefine('announce-live', () => import('@github-ui/announce-live-element'))
lazyDefine('billing-country-and-region-selection', () => import('@github-ui/billing-country-and-region-selection-element'))
lazyDefine('business-shipping-information', () => import('@github-ui/business-shipping-information-element'))
lazyDefine('business-use-billing-information-for-shipping', () => import('@github-ui/business-use-billing-information-for-shipping-element'))
lazyDefine('change-password', () => import('@github-ui/change-password-element'))
lazyDefine('codespace-share-dialog', () => import('@github-ui/codespace-share-dialog-element'))
lazyDefine('cohort-widget', () => import('@github-ui/cohort-widget-element'))
lazyDefine('cookie-consent-link', () => import('@github-ui/cookie-consent-link-element'))
lazyDefine('copilot-business-settings', () => import('@github-ui/copilot-business-settings-element'))
lazyDefine('copilot-coding-guideline-form', () => import('@github-ui/copilot-coding-guideline-form-element'))
lazyDefine('copilot-coding-guideline-path-row', () => import('@github-ui/copilot-coding-guideline-path-row-element'))
lazyDefine('copilot-mixed-license-orgs-list', () => import('@github-ui/copilot-mixed-license-orgs-list-element'))
lazyDefine('copilot-review-feedback', () => import('@github-ui/copilot-review-feedback-element'))
lazyDefine('copilot-summarize-banner', () => import('@github-ui/copilot-summarize-banner-element'))
lazyDefine('copilot-summarize-banner-staffbar-controls', () => import('@github-ui/copilot-summarize-banner-staffbar-controls-element'))
lazyDefine('copilot-text-completion', () => import('@github-ui/copilot-text-completion-element'))
lazyDefine('copilot-user-settings', () => import('@github-ui/copilot-user-settings-element'))
lazyDefine('copy-project', () => import('@github-ui/copy-project-element'))
lazyDefine('edit-hook-secret', () => import('@github-ui/edit-hook-secret-element'))
lazyDefine('emu-contribution-blocked-hint', () => import('@github-ui/emu-contribution-blocked-hint-element'))
lazyDefine('experimental-action-list', () => import('@github-ui/experimental-action-list-element'))
lazyDefine('fgp-search', () => import('@github-ui/fgp-search-element'))
lazyDefine('flywheel-return-to-tour', () => import('@github-ui/flywheel-return-to-tour-element'))
lazyDefine('fullstory-capture', () => import('@github-ui/fullstory-capture-element'))
lazyDefine('ghcc-consent', () => import('@github-ui/ghcc-consent-element'))
lazyDefine('inline-security-checkup-notice', () => import('@github-ui/inline-security-checkup-notice-element'))
lazyDefine('input-page-refresh', () => import('@github-ui/input-page-refresh-element'))
lazyDefine('issue-create', () => import('@github-ui/issue-create-element'))
lazyDefine('markdown-accessiblity-table', () => import('@github-ui/markdown-accessiblity-table-element'))
lazyDefine('microsoft-analytics', () => import('@github-ui/microsoft-analytics-element'))
lazyDefine('microsoft-analytics-event', () => import('@github-ui/microsoft-analytics-event-element'))
lazyDefine('org-role-row', () => import('@github-ui/org-role-row-element'))
lazyDefine('query-builder', () => import('@github-ui/query-builder-element'))
lazyDefine('react-partial-anchor', () => import('@github-ui/react-partial-anchor-element'))
lazyDefine('security-analysis-ghas', () => import('@github-ui/security-analysis-ghas-element'))
lazyDefine('share-button-form-submit-handler', () => import('@github-ui/share-button-form-submit-handler-element'))
lazyDefine('show-dialog-on-load', () => import('@github-ui/show-dialog-on-load-element'))
lazyDefine('sidebar-pinned-topics', () => import('@github-ui/sidebar-pinned-topics-element'))
lazyDefine('site-header-logged-in-user-menu', () => import('@github-ui/site-header-logged-in-user-menu-element'))
lazyDefine('stafftools-topics-table', () => import('@github-ui/stafftools-topics-table-element'))
lazyDefine('task-component', () => import('@github-ui/task-component-element'))
lazyDefine('team-sync-okta-config-form', () => import('@github-ui/team-sync-okta-config-form-element'))
lazyDefine('topic-feeds-toast-trigger', () => import('@github-ui/topic-feeds-toast-trigger-element'))
lazyDefine('updatable-content', () => import('@github-ui/updatable-content-element'))
lazyDefine('visible-password', () => import('@github-ui/visible-password-element'))
lazyDefine('webauthn-get', () => import('@github-ui/webauthn-get-element'))
lazyDefine('webauthn-subtle', () => import('@github-ui/webauthn-subtle-element'))
lazyDefine('profile-pins', ()=>import('./github/profile/profile-pins-element'));
lazyDefine('emoji-picker', ()=>import('./github/emoji-picker-element'));
lazyDefine('insights-query', ()=>import('./github/insights/insights-query'));
lazyDefine('remote-clipboard-copy', ()=>import('./github/behaviors/remote-clipboard-copy'));
lazyDefine('series-table', ()=>import('./github/insights/series-table'));
lazyDefine('line-chart', ()=>import('./github/insights/line-chart'));
lazyDefine('bar-chart', ()=>import('./github/insights/bar-chart'));
lazyDefine('column-chart', ()=>import('./github/insights/column-chart'));
lazyDefine('stacked-area-chart', ()=>import('./github/insights/stacked-area-chart'));
lazyDefine('hero-stat', ()=>import('./github/insights/hero-stat'));
lazyDefine('pulse-authors-graph', ()=>import('./github/graphs/pulse-authors-graph-element'));
lazyDefine('community-contributions-graph', ()=>import('./github/graphs/community-contributions'));
lazyDefine('discussion-page-views-graph', ()=>import('./github/graphs/discussion-page-views'));
lazyDefine('discussions-daily-contributors', ()=>import('./github/graphs/discussions-daily-contributors'));
lazyDefine('discussions-new-contributors', ()=>import('./github/graphs/discussions-new-contributors'));
lazyDefine('code-frequency-graph', ()=>import('./github/graphs/code-frequency-graph-element'));
lazyDefine('contributors-graph', ()=>import('./github/graphs/contributors-graph-element'));
lazyDefine('traffic-clones-graph', ()=>import('./github/graphs/traffic-clones-graph-element'));
lazyDefine('traffic-visitors-graph', ()=>import('./github/graphs/traffic-visitors-graph-element'));
lazyDefine('commit-activity-graph', ()=>import('./github/graphs/commit-activity-graph-element'));
lazyDefine('marketplace-insights-graph', ()=>import('./github/graphs/marketplace-insights-graph-element'));
lazyDefine('user-sessions-map', ()=>import('./github/settings/user-sessions-map-element'));
lazyDefine('reload-after-polling', ()=>import('./github/behaviors/reload-after-polling-element'));
lazyDefine('query-builder', ()=>import('@github-ui/query-builder-element'));
lazyDefine('package-dependencies-security-graph', ()=>import('./github/graphs/package-dependencies-security-graph-element'));
lazyDefine('.js-sub-dependencies', ()=>import('./github/dependencies'));
lazyDefine('network-graph', ()=>import('./github/graphs/network-graph-element'));
lazyDefine('inline-machine-translation', ()=>import('./github/localization/inline-machine-translation-element'));
lazyDefine('custom-patterns-filter', ()=>import('./github/secret-scanning/custom-patterns-filter'));
